var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-header',[_c('b-card-title',[_vm._v("Faturamento mensal por Cliente")]),(!_vm.showCreate)?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MoreVerticalIcon","size":"18"},on:{"click":function($event){_vm.showCreate = true}}}):_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MoreHorizontalIcon","size":"18"},on:{"click":function($event){_vm.showCreate = false}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCreate),expression:"showCreate"}],staticClass:"mb-1"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"5"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-dateformat5","date-format-options":{
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'short',
          },"placeholder":"Início","local":"br","size":"sm"},model:{value:(_vm.date_ini),callback:function ($$v) {_vm.date_ini=$$v},expression:"date_ini"}})],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-dateformat6","date-format-options":{
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'short',
          },"placeholder":"Fim","local":"br","size":"sm"},model:{value:(_vm.date_end),callback:function ($$v) {_vm.date_end=$$v},expression:"date_end"}})],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.isLoading,"opacity":"0.70","rounded":"sm"}},[_c('b-row',{staticClass:"justify-content-md-center mt-2"},[(!_vm.isLoading)?_c('chartjs-doughnut-component',{staticClass:"mb-3",attrs:{"height":310,"data":_vm.chartData,"options":_vm.chartOptions}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }