<template>
  <section id="dashboard-ecommerce">

    <b-row class="match-height">
      <b-col lg="4">
        <!-- Faturamento Geral -->
        <invoices />
      </b-col>
      <b-col lg="4">
        <!-- Faturamento mensal por Cliente -->
        <invoices-tenant />
      </b-col>
      <b-col lg="4">
        <!-- Estatísticas de Uso -->
        <events-dashboard />
      </b-col>
    </b-row>

    
    <!-- <b-row class="match-height">
      <b-col lg="6"> -->
        <!-- Lista de Tickets Abertos -->
        <!-- <support-list /> 
      </b-col>
      <b-col>
        <b-card> -->
          <!-- Grafico Tickets Suporte -->
          <!-- <support-card />
        </b-card>
      </b-col>
    </b-row> -->

    <!-- <b-row class="match-height"> </b-row> -->
    <b-row class="match-height">
      <b-col lg="6">
        <!-- Uso geral de envios -->
        <charts-line-area />        
      </b-col>
      <b-col lg="6">
        
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from "vue-ripple-directive";
import SupportList from "@/components/dashboard/SupportList";
import SupportCard from "@/components/dashboard/SupportCard.vue";
import Invoices from "@/components/dashboard/Invoices";
import { mapActions, mapState } from "vuex";
import InvoicesTenant from "@/components/dashboard/InvoicesTenant.vue";
import chartsArea from "@/components/dashboard/ProfitExpenses";
import ChartsLineArea from "@/components/dashboard/UseSystem";
import EventsDashboard from "@/components/dashboard/StatisticUse.vue";
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  components: {
    SupportCard,
    SupportList,
    Invoices,
    InvoicesTenant,
    chartsArea,
    ChartsLineArea,
    EventsDashboard,
  },
  computed: {},
  methods: {},
  created() {},
  data() {
    return {};
  },
  directives: {
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
