<template>
  <b-card>
      <h2 class="text-center">Nenhum Ticket Aberto</h2>
  </b-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
export default {
  data() {
    return {

    };
  },
  computed: {
    
  },
  methods: {
   
  },
  created() {
    
  },
  watch: {

  },
  directives: {
    Ripple,
  },
};
</script>