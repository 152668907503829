<template>
  <b-card>
    <b-card-header>
      <b-card-title>Faturamento mensal por Cliente</b-card-title>
      <feather-icon
        icon="MoreVerticalIcon"
        size="18"
        class="cursor-pointer"
        v-if="!showCreate"
        @click="showCreate = true"
      />
      <feather-icon
        icon="MoreHorizontalIcon"
        size="18"
        class="cursor-pointer"
        v-else
        @click="showCreate = false"
      />
    </b-card-header>
    <div v-show="showCreate" class="mb-1">
      <b-row class="justify-content-center">
        <b-col md="5">
          <b-form-datepicker
            id="datepicker-dateformat5"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }"
            v-model="date_ini"
            placeholder="Início"
            local="br"
            size="sm"
          />
        </b-col>
        <b-col md="5">
          <b-form-datepicker
            id="datepicker-dateformat6"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }"
            v-model="date_end"
            placeholder="Fim"
            local="br"
            size="sm"
          />
        </b-col>
      </b-row>
    </div>
    
    <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
      <b-row class="justify-content-md-center mt-2">
        <chartjs-doughnut-component
          :height="310"
          v-if="!isLoading"
          :data="chartData"
          :options="chartOptions"
          class="mb-3"
        />
      </b-row>
    </b-overlay>

  </b-card>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle } from "bootstrap-vue";
import ChartjsDoughnutComponent from "../charts/DoughnutChart";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapState } from "vuex";
import axios from "@axios";
import { $themeColors } from "@themeConfig";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

export default {
  components: {
    ChartjsDoughnutComponent,
    BCard,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  data() {
    return {
      isLoading: true,
      
      date_ini: null,
      date_end: null,
      showCreate: false,

      colors: [],
      
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 40,
        legend: {
          display: true,
          position: "bottom",
        },
        tooltips: {
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: "rgba(0, 0, 0, 0.25)",
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        plugins: {
          datalabels: {
            display: true,
            clip: true,
            color: "white",
            textStrokeColor: "black",
            textStrokeWidth: 1,
            font: {
              family: "Montserrat",
              weight: "bold",
              size: 13,
            },
            formatter: function(value, context) {
              return parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
            }
          },
        },
      },
    };
  },
  watch: {
    date_ini() {
      this.refreshChart()
    },
    date_end() {
      this.refreshChart()
    }
  },
  methods:{
    async refreshChart(){
      this.isLoading = true;
      var date = new Date();
      try {
        const { data: monthInvoices } = await axios.get(
          "admin/dashboard/monthInvoicesTenant", {
            params: {
              date_start: this.date_ini,
              date_end: this.date_end
            } 
          }
        );      
        // for(let i=0;i<monthInvoices.length;i++){
        //   this.colors.push('#'+Math.floor(Math.random()*16777215).toString(16));
        // }  
       
        this.chartData = {
          labels: monthInvoices.map((invoice) => {
            return invoice.tenant.company_name;
          }),
          datasets: [
            {
              data: monthInvoices.map((invoice) => {
                return invoice.total_value;
              }),   
              backgroundColor: ['#7367F0', '#4f40e6', '#3f1fde', '#2c09db', '#2104b5', '#1b0299', '#16017d', '#120166', '#0d014d', '#0a0138'], 
            },
          ],
        };    
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    }
  },
  async mounted() {
    this.isLoading = true;
    var date = new Date();
    try {
      const { data: monthInvoices } = await axios.get(
        "admin/dashboard/monthInvoicesTenant", {
            params: {
              date_start: new Date(date.getFullYear(), date.getMonth(), 1),
              date_end: new Date(date.getFullYear(), date.getMonth() + 1, 0)
            } 
        }
      );
     
      // for(let i=0;i<monthInvoices.length;i++){
      //   this.colors.push('#'+Math.floor(Math.random()*16777215).toString(16));
      // }
      this.chartData = {
        labels: monthInvoices.map((invoice) => {
          return invoice.tenant.company_name;
        }),
        datasets: [
          {
            data: monthInvoices.map((invoice) => {
              return invoice.total_value;
            }),   
            backgroundColor: ['#7367F0', '#4f40e6', '#3f1fde', '#2c09db', '#2104b5', '#1b0299', '#16017d', '#120166', '#0d014d', '#0a0138'],    
          },
        ],
      };
  
      this.isLoading = false;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
