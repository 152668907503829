<template>
  <b-card class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>Estatísticas de Uso</b-card-title>
      <feather-icon
        icon="MoreVerticalIcon"
        size="18"
        class="cursor-pointer"
        v-if="!showCreate"
        @click="showCreate = true"
      />
      <feather-icon
        icon="MoreHorizontalIcon"
        size="18"
        class="cursor-pointer"
        v-else
        @click="showCreate = false"
      />
    </b-card-header>
    <div v-show="showCreate" class="m-1">
      <b-row>
        <b-col>
          <b-form-datepicker
            id="datepicker-dateformat3"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }"
            v-model="date_ini"
            placeholder="Início"
            local="br"
          />
        </b-col>
        <b-col>
          <b-form-datepicker
            id="datepicker-dateformat4"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }"
            v-model="date_end"
            placeholder="Fim"
            local="br"
          />
        </b-col>
      </b-row>
    </div>
    <!-- <b-row class="ml-1 mr-1 mt-2">
      <b-col md="6" xl="7" class="p-1">
        <b-card-title class="mb-1"> Estatísticas de Uso </b-card-title>
      </b-col>
      <b-col md="6" xl="5" class="p-1">
        <b-form-select v-model="monthFilter" :options="monthFilterOptions" />
      </b-col>
    </b-row> -->
    <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
      <b-card-body>
        <div
          v-for="(tr, indextr) in dashboard"
          :key="indextr"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar rounded size="42" :variant="tr.color">
                <feather-icon size="18" :icon="tr.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ tr.name }}
              </h6>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder">
            {{ tr.amount }}
          </div>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: true,
      date_filter: null,

      showCreate: false,

      date_ini: null,
      date_end: null,
    };
  },
  computed: mapState("dashboard", ["dashboard"]),
  methods: {
    fetchUsabilities() {
      this.$store
        .dispatch("dashboard/fetchUsabilities", {
          date_start: this.date_ini,
          date_end: this.date_end,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    this.fetchUsabilities();
  },
  watch: {
    dashboard() {
      this.isLoading = false;
    },
    date_ini(val) {
      this.fetchUsabilities();
    },
    date_end(val) {
      this.fetchUsabilities();
    },
  },
};
</script>
