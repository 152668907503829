<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Uso do sistema
        </b-card-title>
      </div>
      <!--/ title and subtitle -->
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
        <chartjs-line-component
          :height="450"
          v-if="!isLoading"
          :data="chartData"
          :options="chartOptions"
        />
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import ChartjsLineComponent from "../charts/LineChart";
import { $themeColors } from "@themeConfig";
import axios from "@axios";

export default {
  components: {
    BCard,   
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    ChartjsLineComponent,
  },
  data() {
    return {
      isLoading: true,

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: '#6e6b7b',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                // stepSize: 100,
                // min: 0,
                fontColor: '#6e6b7b',
              },
              scaleLabel: {
                display: true,
              }
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false
          }          
        },  
      },
      chartData: null,
    } 
  },
  async mounted() {
    this.isLoading = true;
    try {
      const { data: useSystem } = await axios.get(
        "admin/dashboard/useSystem"
      );
      this.chartData = {
        labels: useSystem.map((u) => {          
          return u.month+"-"+u.year;
        }),
        datasets: [
          {
            data: useSystem.map((u) => {
              return u.contador;
            }),  
            label: 'Total',
            lineTension: 0,
            backgroundColor: '#84D0FF',
            borderColor: '#299AFF',
            pointStyle: 'circle',
            pointRadius: 6,
            pointBorderWidth: 3,
            pointBorderColor: $themeColors.light,
            pointHoverRadius: 6,
            pointHoverBorderWidth: 5,        
            pointHoverBorderColor: $themeColors.light,
            pointHoverBackgroundColor: '#299AFF',   
          },
        ],
      };    
      this.isLoading = false;
    } catch (e) {
      console.error(e);
    }
  }
}
</script>
