<template>
  <b-card class="earnings-card border-primary">
    <b-card-header>
      <b-card-title>Faturamento Geral</b-card-title>
      <feather-icon
        icon="MoreVerticalIcon"
        size="18"
        class="cursor-pointer"
        v-if="!showCreate"
        @click="showCreate = true"
      />
      <feather-icon
        icon="MoreHorizontalIcon"
        size="18"
        class="cursor-pointer"
        v-else
        @click="showCreate = false"
      />
    </b-card-header>
    <div v-show="showCreate" class="mb-1">
      <b-row class="justify-content-center">
        <b-col md="5">
          <b-form-datepicker
            id="datepicker-dateformat"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }"
            v-model="date_ini"
            placeholder="Início"
            local="br"
            size="sm"
          />
        </b-col>
        <b-col md="5">
          <b-form-datepicker
            id="datepicker-dateformat2"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }"
            v-model="date_end"
            placeholder="Fim"
            local="br"
            size="sm"
          />
        </b-col>
      </b-row>
    </div>

    <b-row class="justify-content-md-center">
      <b-col cols="6" class="text-center">
        <div class="font-small-5">Pago</div>
        <h5 class="mb-1">
          {{ paid_value | moneyFormat }}
        </h5>
      </b-col>
      <b-col cols="6" class="text-center">
        <div class="font-small-5">Em aberto</div>
        <h5 class="mb-1">
          {{ open_value | moneyFormat }}
        </h5>
      </b-col>
    </b-row>

    <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
      <b-row class="justify-content-md-center mt-2">
        <chartjs-doughnut-component
          :height="260"
          v-if="!isLoading"
          :data="chartData"
          :options="chartOptions"
          class="mb-3"
        />
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
import ChartjsDoughnutComponent from "../charts/DoughnutChart";
import { $themeColors } from "@themeConfig";
import axios from "@axios";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    ChartjsDoughnutComponent,
  },
  data() {
    return {
      isLoading: true,

      showCreate: false,

      date_ini: null,
      date_end: null,

      paid_value: 0,
      open_value: 0,

      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: {
          display: true,
          position: "bottom",
        },
        tooltips: {
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: "rgba(0, 0, 0, 0.25)",
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        plugins: {
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              weight: "bold",
              size: 16,
            },
          },
        },
      },       
    };
  },
  watch: {
    date_ini() {
      this.refreshChart()
    },
    date_end() {
      this.refreshChart()
    }
  },
  methods:{
    async refreshChart(){

      this.isLoading = true;
      try {
        const { data: monthInvoices } = await axios.get(
          "admin/dashboard/monthInvoices", {
            params: {
              date_start: this.date_ini,
              date_end: this.date_end
            }           
          }
        );
        this.chartData = {
          labels: monthInvoices.labels,
          datasets: [
            {
              data: monthInvoices.data,
              backgroundColor: ["#00C851", "#ffbb33", "#ff4444"],
            },
          ],
        };
        this.paid_value = monthInvoices.paid_value;
        this.open_value = monthInvoices.open_value;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    }
  },
  async mounted() {
    this.isLoading = true;
    var date = new Date();
    try {
      const { data: monthInvoices } = await axios.get(
        "admin/dashboard/monthInvoices", {
            params: {
              date_start: new Date(date.getFullYear(), date.getMonth(), 1),
              date_end: new Date(date.getFullYear(), date.getMonth() + 1, 0)
            } 
        }
      );
      this.chartData = {
        labels: monthInvoices.labels,
        datasets: [
          {
            data: monthInvoices.data,
            backgroundColor: ["#00C851", "#ffbb33", "#ff4444"],
          },
        ],
      };
      this.paid_value = monthInvoices.paid_value;
      this.open_value = monthInvoices.open_value;
      this.isLoading = false;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
</style>
